import React                                from "react";
import Navigation                           from "../../../components/Navigation/Navigation";
import routerConstants                      from "../../../constants/router-constants";
import {Breadcrumb, Button, Card, Col, Row} from "react-bootstrap";
import {Link, withRouter}                   from "react-router-dom";
import {connect}                            from "react-redux";
import {useForm}                            from "react-hook-form";
import Select                               from "react-select";
import {
    getSingleWebsite,
    getWebsites
}                                           from "../../../redux/Websites/websites.async-actions";
import {
    getProductsList
}                                           from "../../../redux/Products/products.async-actions";
import {
    getAllEmailTemplatesList,
    getSingleService,
    updateServices
}                                           from "../../../redux/Services/services.async-actions";
import Loader                               from "../../../components/Common/Loader";

class EditServicesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu      : true,
            websites      : {},
            products      : {},
            emailTemplates: {}
        };

        this.websitesOptions = [];
        this.productsOptions = [];
        this.emailTemplates  = [];
    }

    onWebsitesValueChange = websites => this.setState({websites});

    onProductsValueChange = products => this.setState({products});

    onEmailTemplateValueChange = emailTemplates => this.setState({emailTemplates});

    async componentDidMount() {
        await this.props.getProductsList();
        await this.props.getWebsites();
        await this.props.getSingleService(this.props.match.params.services_uuid);
        await this.props.getAllEmailTemplatesList();

        if (null === this.props.errorWebsite) {
            this.websitesOptions = this?.props?.websiteList?.map(
                website => {
                    return {
                        value: website["@id"],
                        label: website.domain
                    };
                }
            );

            this.setState(
                {
                    websites: this.props.singleServices.website.map(website => {
                        return {
                            value: website["@id"],
                            label: website.domain
                        };
                    })
                }
            );
        }

        if (null === this.props.errorProduct) {
            this.productsOptions = this.props.productList.map(
                product => {
                    return {
                        value: product["@id"],
                        label: product.name
                    };
                }
            );

            let products = {
                value: this?.props?.singleServices?.productSubscription?.[0]?.["@id"] ?? "",
                label: this?.props?.singleServices?.productSubscription?.[0]?.name ?? ""
            };

            this.setState(
                {
                    products
                }
            );
        }

        this.emailTemplates = this
            .props
            .serviceEmailTemplates
            .map(
                template => {
                    return {
                        value: template["@id"],
                        label: template.name
                    };
                }
            );

        let emailTemplates = {
            value: this?.props?.singleServices?.welcomeEmail?.["@id"] ?? "",
            label: this?.props?.singleServices?.welcomeEmail?.name ?? ""
        };

        this.setState(
            {
                emailTemplates
            }
        );
    }

    ServicesForm = () => {
        const {name, description, slug} = this.props.singleServices;

        this.props.setValue("name", name);
        this.props.setValue("description", description);
        this.props.setValue("slug", slug);

        return <>
            <Card>
                <Card.Body>
                    <Card.Title>Edit Services</Card.Title>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <div className="row">
                            <div className="col">
                                <label
                                    htmlFor="companyName"
                                >
                                    Name
                                </label>
                                <input
                                    id="companyName"
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    name="name"
                                    {...this.props.register("name")}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="description"
                                >
                                    Description
                                </label>
                                <input
                                    id="description"
                                    type="text"
                                    className="form-control"
                                    placeholder="Description"
                                    name="description"
                                    {...this.props.register("description")}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label
                                    htmlFor="slug"
                                >
                                    Slug
                                </label>
                                <input
                                    id="slug"
                                    type="text"
                                    className="form-control"
                                    placeholder="Slug"
                                    readOnly
                                    {...this.props.register("slug")}
                                />
                            </div>
                        </div>

                        <label>Product Subscription</label>
                        <Select
                            value={this.state.products}
                            onChange={this.onProductsValueChange}
                            options={this.productsOptions}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            menuPlacement={"auto"}
                        />
                        {
                            this.props.errorProduct !== null &&
                            <span className="text-danger">
                                Something went wrong and the product subscription cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <label>Website</label>
                        <Select
                            value={this.state.websites}
                            options={this.websitesOptions}
                            onChange={this.onWebsitesValueChange}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            isMulti
                            menuPlacement={"auto"}
                        />
                        {
                            this.props.errorWebsite !== null &&
                            <span className="text-danger">
                                Something went wrong and the website cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <label>Welcome Email</label>
                        <Select
                            value={this.state.emailTemplates}
                            options={this.emailTemplates}
                            onChange={this.onEmailTemplateValueChange}
                            backspaceRemovesValue={true}
                            escapeClearsValue={true}
                            menuPlacement={"auto"}
                            isSearchable
                            isClearable
                        />
                        {
                            null !== this.props.serviceEmailTemplatesError &&
                            <span className="text-danger">
                                Something went wrong and the email templates cannot be loaded. Please
                                contact the development team to ask for more information.
                            </span>
                        }

                        <div style={{padding: "10px"}} />

                        <div className="text-center">
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                Update Services
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </>;
    };

    goToRoute(route) {
        this.props.history.push(route);
    }

    onSubmit = async (data) => {
        const dataPayload = {
            slug        : this.props.singleServices.slug,
            name        : data?.name,
            description : data?.description,
            servicesId  : this.props.match.params.services_uuid,
            productsId  : this.state.products.value,
            websitesId  : this.state.websites.map(website => website.value),
            welcomeEmail: this?.state?.emailTemplates?.value ?? null
        };

        await this.props.updateServices(dataPayload);

        if (this.props.editSuccess) {
            this.goToRoute(`/list-services`);
        }
    };

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    render() {
        let loader = null;

        if (
            this.props.singleServiceLoading ||
            this.props.serviceEmailTemplatesLoading
        ) {
            return <Loader message="Loading..." />;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {null !== this.props.editError &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.editError}
                        </div>
                    }

                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Edit Services</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listServices} className="breadcrumb-item">
                                List Services
                            </Link>
                            <Breadcrumb.Item active>
                                Edit Services
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row>
                        <Col lg={12}>
                            {
                                this.ServicesForm()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>;
    }
}

const EditServicesPage = (props) => {
    const {
              register,
              handleSubmit,
              setValue
          } = useForm();

    return <EditServicesComponent
        {...props}
        setValue={setValue}
        register={register}
        handleSubmit={handleSubmit}
    />;
};

const mapStateToProps = state => {
    return {
        productList                 : state.products.productsList,
        websiteList                 : state.websites.set_websites,
        errorWebsite                : state.websites.website_form_fail,
        errorProduct                : state.products.productListError,
        singleServices              : state.services.setSingleServices,
        singleServiceLoading        : state.services.setSingleServicesLoading,
        editSuccess                 : state.services.setEditServicesSuccess,
        editError                   : state.services.setEditServicesError,
        serviceEmailTemplates       : state.services.serviceEmailTemplates,
        serviceEmailTemplatesLoading: state.services.serviceEmailTemplatesLoading,
        serviceEmailTemplatesError  : state.services.serviceEmailTemplatesError
    };
};

const mapActionsToProps = {
    getProductsList,
    getWebsites,
    getSingleWebsite,
    updateServices,
    getSingleService,
    getAllEmailTemplatesList
};

const EditServicesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditServicesPage);

export default withRouter(EditServicesConnected);
